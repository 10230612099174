import React from 'react'
import { LabelContainer, InputInfo, InputError, LabelStyled } from './styles'
import { LabelTypes } from './types'

const Label: React.FC<LabelTypes> = ({
  full,
  error,
  info,
  label,
  children,
  ...props
}) => {
  const displayInfoError = () => {
    if (info && !error) return <InputInfo>{info}</InputInfo>
    if (error) return <InputError>{error}</InputError>
    return null
  }
  return (
    <LabelContainer full={full} {...props}>
      {label && <LabelStyled>{label}</LabelStyled>}
      {children}
      {displayInfoError()}
    </LabelContainer>
  )
}

export default Label
