import styled from 'styled-components'
import { BREAK_POINTS } from '../../../../services/styles/constants'

export const Td = styled.td`
  padding: 16px;
  text-align: left;
  vertical-align: initial;
  background-color: var(--white);
  vertical-align: middle;
  &:first-child {
    width: 48px;
  }
  &:last-child {
    > td:first-child {
      border-bottom-left-radius: 3px;
    }
    > td:last-child {
      border-bottom-right-radius: 3px;
    }
  }

  @media (max-width: ${BREAK_POINTS.small}px) {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: 1rem;
    text-align: right;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    &:first-child {
      width: 100%;
    }
    &:nth-child(2) {
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
    }
    &:last-child {
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px;
      border-bottom: 0;
    }

    &::before {
      /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
      content: attr(data-label);
      font-size: 0.85rem;
      font-weight: bold;
      text-transform: uppercase;
    }
  }
`
