import React, { useState, useRef, useEffect } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { BsChevronDown } from 'react-icons/bs'
import { SelectContainer, SearchContainer } from './styles'
import Input from '../Input'
import Button from '../Button'
import { useOnClickOutside } from '../../services/hooks/useOnClickOutside'
import { SelectTypes, OptionsType } from './types'
import { InputInfo, InputError, LabelStyled } from '../Label/styles'
import SelectTags from './components/SelectTags'
import { useNonInitialEffect } from '../../services/hooks/useNonInitialEffect'

const ulVariants = {
  open: {
    opacity: 1,
    y: 8
  },
  close: {
    opacity: 0,
    y: 30
  }
}

const Select = ({
  options,
  noOptionsMessage = 'No options found.',
  placeholder = '',
  searchable,
  value,
  setValue,
  info,
  error,
  label,
  id,
  isMulti = false,
  disabled = false,
  full = false,
  ...props
}: SelectTypes) => {
  // Create a ref to detects clicks outside the element
  const SelectContainerRef = useRef(null)
  // State for displaying the options
  const [toggle, setToggle] = useState(false)
  // Filter options state for when we write on the input
  const [filterOptions, setFilterOptions] = useState(options)
  // Input search value
  const [searchValue, setSearchValue] = useState<string>('')

  // Hook to detect the click outside the reference
  useOnClickOutside(SelectContainerRef, () => {
    // If is not multi and there was a value selected, set the search value to value.label
    if (
      !isMulti &&
      value &&
      !Array.isArray(value) &&
      searchValue !== value.label
    ) {
      setSearchValue(value.label)
    }

    // If is multi, clear search value, reset the filter options to the initial value and close the toggle
    if (isMulti && searchValue) setSearchValue('')
    setFilterOptions(options)
    setToggle(false)
  })

  useEffect(() => {
    // If is not multi and there was a value selected, set the search value to value.label
    // This is duplicated here to give a initial value to the searchValue
    if (
      !isMulti &&
      value &&
      !Array.isArray(value) &&
      searchValue !== value.label
    ) {
      setSearchValue(value.label)
    }
    // If is multi and a value is entered, reset the search value and the filter options
    // but dont close the modal to continue inserting items
    if (isMulti && searchValue) {
      setSearchValue('')
      setFilterOptions(options)
    }
  }, [value])

  useNonInitialEffect(() => {
    // If option state change, refresh the options
    setFilterOptions(options)
  }, [options])

  const handleResultClicked = (option: OptionsType) => {
    if (isMulti) {
      // If value is undefined. populate
      if (!value) return setValue([option])

      // If value and searchValue exist
      if (value && Array.isArray(value)) {
        // If searchValue already contains the selected Object. Remove
        const exist = value.some((x) => x.label === option.label)
        if (exist) {
          const filteredValue = value.filter((x) => x.label !== option.label)
          setValue(filteredValue)
          return
        }

        // If Object dont exist in the array. Add it
        setValue([...value, option])
        return
      }
      return
    }

    // If values are !isMulti (Object)
    setToggle(false)
    setValue(option)
    setSearchValue(option.label)
  }

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    setSearchValue(value)
    const filterList = options.filter((x) =>
      x.label.toLowerCase().includes(value.toLowerCase())
    )

    setFilterOptions(filterList)
  }

  const insertTags = (selectedValue: string) => {
    const findTag = options.find((option) => option.label === selectedValue)
    if (findTag) handleResultClicked(findTag)
  }

  const renderOptions = () => {
    if (!toggle && info && !error) {
      return <InputInfo style={{ position: 'absolute' }}>{info}</InputInfo>
    }

    if (!toggle && error) {
      return <InputError style={{ position: 'absolute' }}>{error}</InputError>
    }

    if (filterOptions.length === 0) {
      return (
        <div>
          <p>{noOptionsMessage}</p>
        </div>
      )
    }

    if (isMulti && value && Array.isArray(value)) {
      // Order the array by checked
      filterOptions.sort((a, b) => {
        if (value.some((x) => x.label === a.label)) {
          return -1
        }
        if (value.some((x) => x.label === b.label)) {
          return 1
        }

        return 0
      })
    }

    return (
      <AnimatePresence>
        {toggle && (
          <motion.ul
            key='Ul'
            variants={ulVariants}
            initial='close'
            animate='open'
            exit='close'
          >
            {filterOptions.map((option) => {
              // If isMulti, find if checkbox should be check
              const checked = () => {
                if (isMulti && value && Array.isArray(value)) {
                  return value.some((x) => x.label === option.label)
                }
                return false
              }
              return (
                <motion.li key={option.label} layoutId='listElements'>
                  <Button
                    full
                    simple
                    onClick={() => handleResultClicked(option)}
                  >
                    {option.label}
                    {isMulti && (
                      <Input
                        type='checkbox'
                        onChange={() => handleResultClicked(option)}
                        checked={checked()}
                        tabIndex={-1}
                      />
                    )}
                  </Button>
                </motion.li>
              )
            })}
          </motion.ul>
        )}
      </AnimatePresence>
    )
  }

  const tags =
    isMulti && value && Array.isArray(value)
      ? value.map((val) => val.label)
      : []

  return (
    <SelectContainer
      // Active is for select the first Li element if its filtering
      active={isMulti && searchValue !== ''}
      ref={SelectContainerRef}
      full={full}
      {...props}
    >
      <label htmlFor={id}>
        {label && <LabelStyled>{label}</LabelStyled>}
        <SearchContainer error={error} disabled={disabled} full={full}>
          {isMulti ? (
            <SelectTags
              id={id}
              validValues={options.map((option) => option.label)}
              tags={tags}
              setTags={insertTags}
              inputValue={searchValue}
              toggleDropdown={setToggle}
              handleSearch={handleSearch}
              error={error}
              placeholder={placeholder}
              disabled={disabled}
              full={full}
            />
          ) : (
            <Input
              disabled={disabled}
              readOnly={!searchable}
              autoComplete='off'
              id={id}
              type='text'
              onFocus={() => setToggle(true)}
              value={searchValue}
              onChange={handleSearch}
              placeholder={placeholder}
              onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
                if (e.keyCode === 13 || (e.charCode === 13 && searchValue)) {
                  const filterList = options.filter((x) =>
                    x.label.toLowerCase().includes(searchValue.toLowerCase())
                  )

                  if (filterList.length > 0) {
                    // If the tags are for Select, use setTags
                    handleResultClicked(filterList[0])
                  }
                }
              }}
            />
          )}
          <motion.button
            type='button'
            disabled={disabled}
            animate={{ transform: toggle ? 'rotate(180deg)' : 'rotate(0deg)' }}
            onClick={() => {
              setToggle(!toggle)
            }}
            tabIndex={-1}
          >
            <BsChevronDown />
          </motion.button>
        </SearchContainer>
      </label>
      <div>{renderOptions()}</div>
    </SelectContainer>
  )
}
export default Select
