import React, { useState } from 'react'
import { PaginationContainer, PageButton } from './styles'
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs'
import { PaginationTypes } from './types'
import { useNonInitialEffect } from '../../services/hooks/useNonInitialEffect'

const Pagination = ({
  dataPerPage,
  dataLength,
  setData,
  maxNumbers = 4,
  ...props
}: PaginationTypes) => {
  const [currentPage, setCurrentPage] = useState(1)
  const totalPages = Math.ceil(dataLength / dataPerPage)

  const allNumbers = Array(totalPages)
    .fill(null)
    .map((_, i) => i + 1)

  const NumbersInitialValue = allNumbers.slice(0, maxNumbers)
  const NumbersLastValue = allNumbers.slice(
    allNumbers.length - maxNumbers,
    allNumbers.length
  )
  const [numbers, setNumbers] = useState(NumbersInitialValue)

  const renderButtons = () => {
    return numbers.map((x) => {
      return (
        <PageButton
          status={currentPage === x}
          key={x}
          onClick={() => setCurrentPage(x)}
        >
          {x}
        </PageButton>
      )
    })
  }

  useNonInitialEffect(() => {
    // outOfPage check if the current page is not in the current number list
    // itsGoingForward localize if the current number its higher than the current numbers
    // This two in combination solves the issue when you click on the 3 dots and the numbers don't update
    const outOfPage = !numbers.some((x) => x === currentPage)
    const itsGoingForward = numbers.some((x) => x < currentPage)

    // If last value in array on numbers is reached
    // And the current numbers are not equal to the NumbersLastValue ones
    if (
      (outOfPage && itsGoingForward) ||
      (numbers[maxNumbers - 1] === currentPage &&
        JSON.stringify(numbers) !== JSON.stringify(NumbersLastValue))
    ) {
      // If there is no value in all numbers for the next bunch
      if (!allNumbers[currentPage + maxNumbers - 1]) {
        // Add the last maxNumbers from all numbers
        setNumbers(NumbersLastValue)
      } else {
        // Add the next bunch
        const nextBunch = allNumbers.slice(
          currentPage - 1,
          currentPage - 1 + maxNumbers
        )
        setNumbers(nextBunch)
      }
    }

    // If first value in numbers is reached
    // And the current numbers are not equal to the initial ones
    if (
      (outOfPage && !itsGoingForward) ||
      (numbers[0] === currentPage &&
        JSON.stringify(numbers) !== JSON.stringify(NumbersInitialValue))
    ) {
      // If there is no value in all numbers for the previous bunch
      if (!allNumbers[currentPage - maxNumbers]) {
        // Add the first maxNumbers from all numbers
        setNumbers(NumbersInitialValue)
      } else {
        // Add the previous bunch
        const previousBunch = allNumbers.slice(
          currentPage - maxNumbers,
          currentPage
        )
        setNumbers(previousBunch)
      }
    }

    // Return from page to page
    const from = (currentPage - 1) * dataPerPage
    const to = from + dataPerPage
    setData(from, to)
  }, [currentPage, dataPerPage, dataLength])

  const handleNextPage = () => {
    setCurrentPage(currentPage + 1)
  }
  const handlePrevPage = () => {
    setCurrentPage(currentPage + -1)
  }

  const handleDots = () => {
    setCurrentPage(currentPage - 1 + maxNumbers)
  }

  if (totalPages <= 1) return null

  return (
    <PaginationContainer {...props}>
      <PageButton disabled={currentPage === 1} onClick={handlePrevPage}>
        <BsChevronLeft />
      </PageButton>

      {renderButtons()}
      {allNumbers[currentPage + maxNumbers] && (
        <PageButton onClick={handleDots}>...</PageButton>
      )}
      <PageButton
        disabled={currentPage === totalPages}
        onClick={handleNextPage}
      >
        <BsChevronRight />
      </PageButton>
    </PaginationContainer>
  )
}

export default Pagination
