import React from 'react'
import UtilityTools from '../UtilityTools'
import Input from '../../../Input'
import { RowTypes } from '../../types'
import { Td } from './styles'

const Row = ({
  uid,
  data,
  fields,
  deleteRow,
  editRow,
  viewRow,
  selected,
  selectOne,
  displayCheckBoxes
}: RowTypes) => {
  const cells = fields.map(({ field, header, render, bodyProps }) => {
    let value = data

    if (!field) {
      value = ''
    } else {
      // Check for nested objects
      if (field.includes('.')) {
        const nested = field.split('.')
        nested.map((x) => {
          if (value[x]) {
            value = value[x]
          } else {
            value = '-'
          }
        })
      } else {
        value = value[field]
      }
    }

    if (value === undefined || value === null) {
      value = '-'
    }

    const child = render ? render(value, data) : value

    return (
      <Td
        scope='row'
        data-label={header}
        key={`${data[uid]}-${field}`}
        {...bodyProps}
      >
        {child}
      </Td>
    )
  })

  return (
    <tr>
      {displayCheckBoxes && (
        <Td data-label='Select'>
          <Input
            name={data[uid]}
            checked={selected.some((x) => x === data[uid])}
            type='checkbox'
            onChange={selectOne}
          />
        </Td>
      )}
      {cells}
      {(deleteRow || editRow || viewRow) && (
        <UtilityTools
          uid={data[uid]}
          deleteRow={deleteRow}
          editRow={editRow}
          viewRow={viewRow}
        />
      )}
    </tr>
  )
}

export default Row
