import React from 'react'
import { ButtonStyled } from './styles'
import { ButtonTypes } from './types'

const Button: React.FC<ButtonTypes> = ({
  type = 'button',
  secondary = false,
  simple = false,
  danger = false,
  ghost = false,
  full = false,
  iconLeft,
  iconRight,
  children,
  small,
  ...props
}) => {
  let IconLeft = null
  if (iconLeft) {
    if (typeof iconLeft === 'string') {
      IconLeft = require('react-icons/bs')[iconLeft]
    } else {
      IconLeft = iconLeft
    }
  }

  let IconRight = null
  if (iconRight) {
    if (typeof iconRight === 'string') {
      IconRight = require('react-icons/bs')[iconRight]
    } else {
      IconRight = iconRight
    }
  }
  return (
    <ButtonStyled
      iconLeft={iconLeft}
      iconRight={iconRight}
      type={type}
      full={full}
      danger={danger}
      ghost={ghost}
      secondary={secondary}
      simple={simple}
      small={small}
      {...props}
    >
      {IconLeft && <IconLeft style={{ marginRight: children ? 6 : 0 }} />}
      {children}
      {IconRight && <IconRight style={{ marginLeft: children ? 6 : 0 }} />}
    </ButtonStyled>
  )
}

export default Button
