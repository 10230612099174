import React from 'react'
import { AnimatePresence } from 'framer-motion'
import { ModalTypes } from './types'
import { ModalStyled, ModalCard, ModalFooter } from './styles'
import Button from '../Button'
import { BsX } from 'react-icons/bs'

const backgroundVariant = {
  open: { opacity: 1 },
  closed: {
    opacity: 0
  }
}

const cardVariant = {
  open: {
    opacity: 1,
    y: 0,
    transition: {
      delay: 0.2
    }
  },
  closed: { opacity: 0, y: -150 }
}

const Modal: React.FC<ModalTypes> = ({
  title,
  action,
  actionTitle,
  toggle,
  setToggle,
  children
}) => (
  <AnimatePresence>
    {toggle && (
      <ModalStyled
        variants={backgroundVariant}
        initial='closed'
        animate='open'
        exit='closed'
      >
        <ModalCard
          variants={cardVariant}
          initial='closed'
          animate='open'
          exit='closed'
        >
          <div>
            <h3>{title}</h3>
            <Button simple danger onClick={() => setToggle(false)}>
              <BsX />
            </Button>
          </div>
          {children}
          {actionTitle && (
            <ModalFooter>
              <Button secondary small onClick={() => setToggle(false)}>
                Cancel
              </Button>
              <Button small onClick={action}>
                {actionTitle}
              </Button>
            </ModalFooter>
          )}
        </ModalCard>
      </ModalStyled>
    )}
  </AnimatePresence>
)

export default Modal
