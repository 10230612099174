import React from 'react'
import styled from 'styled-components'
import { BREAK_POINTS } from '../../services/styles/constants'

export const PaginationContainer = styled.div`
  display: flex;
  margin-top: 16px;

  @media (max-width: ${BREAK_POINTS.small}px) {
    justify-content: center;
  }
`
interface PageButton extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  status?: boolean
}

export const PageButton = styled(({ status, ...props }: PageButton) => (
  <button type='button' {...props} />
))`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: normal;
  padding: 0;
  border: 1px solid var(--border);
  background-color: var(--white);
  width: 30px;
  min-height: 30px;
  border-radius: 3px;
  margin-right: 4px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  ${({ status }) =>
    status && {
      backgroundColor: 'var(--primary)',
      color: 'var(--white)',
      borderColor: 'var(--primary)'
    }}
  &:last-child {
    margin-right: 0;
  }
  &:hover:enabled {
    background-color: var(--primary);
    color: var(--white);
    border-color: var(--primary);
  }
  &:focus {
    outline: 0;
    border-color: var(--primary);
  }
  &:disabled {
    background-color: var(--background-disabled);
    cursor: initial;
  }

  @media (max-width: ${BREAK_POINTS.small}px) {
    width: 50px;
    min-height: 50px;
    font-size: 1.333rem;
  }
`
