import React, { useEffect, useRef, useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import Button from '../../../Button'
import { InputTagStyled, OptionsContainer } from '../../../InputTag/styles'

type SelectTagsTypes = {
  toggleDropdown: React.Dispatch<React.SetStateAction<boolean>>
  inputValue: string // Input search value
  validValues: string[] // to only accept values from this array for Select component
  tags: string[]
  setTags: (selectedValue: string) => void // Instead using a hook it returns the selected option
  handleSearch: (e: React.ChangeEvent<HTMLInputElement>) => void // Instead using a hook it returns the selected option
  error?: string
  id?: string
  placeholder: string
  disabled: boolean
  full: boolean
}

const SelectTags = ({
  inputValue,
  validValues,
  tags,
  setTags,
  toggleDropdown,
  handleSearch,
  error,
  id,
  placeholder,
  disabled,
  full
}: SelectTagsTypes) => {
  const [isFocus, setIsFocus] = useState(false)
  const containerRef = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    // If tags changes, scroll to right
    if (tags.length > 0) {
      containerRef.current?.scrollBy({
        left: containerRef.current.scrollWidth,
        behavior: 'smooth'
      })
    }
  }, [tags])

  const renderTags = () => {
    return tags.map((option) => (
      <motion.div
        layoutId='Tags'
        key={option}
        initial={{ opacity: 0, x: -10, transform: 'scale(1.1)' }}
        animate={{ opacity: 1, x: 0, transform: 'scale(1)' }}
        exit={{
          opacity: 0,
          x: -10,
          transform: 'scale(1.1)',
          transition: { duration: 0.2 }
        }}
      >
        <Button
          disabled={disabled}
          small
          iconRight='BsXCircleFill'
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
            e.stopPropagation()
            setTags(option)
          }}
        >
          {option}
        </Button>
      </motion.div>
    ))
  }

  return (
    <InputTagStyled
      full={full}
      isDisabled={disabled}
      isError={Boolean(error)}
      isFocus={isFocus}
      ref={containerRef}
      role='button'
      onClick={() => {
        // Focus the input on click outside fake input (container)
        if (!id) inputRef.current?.focus()
      }}
    >
      <OptionsContainer isFocus={isFocus} isEmpty={tags.length === 0}>
        <AnimatePresence>{tags.length > 0 && renderTags()}</AnimatePresence>
      </OptionsContainer>
      <input
        ref={inputRef}
        placeholder={placeholder}
        id={id}
        type='text'
        autoComplete='off'
        value={inputValue}
        onFocus={() => {
          // Set give css styles to container
          setIsFocus(true)
          toggleDropdown(true)
        }}
        onBlur={() => {
          // Remove css styles from container
          setIsFocus(false)
        }}
        onClick={(e: React.MouseEvent<HTMLInputElement>) => {
          e.stopPropagation()
        }}
        onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
          // When Enter is pressed
          if (e.keyCode === 13 || e.charCode === 13) {
            // If valid Values prop is passed
            if (validValues && inputValue) {
              // If there is a value on the input coming from props or state
              // Find the first result matching the words you entered in the input
              const filterList = validValues.filter((x) =>
                x.toLowerCase().includes(inputValue.toLowerCase())
              )

              if (filterList.length > 0) {
                // If the tags are for Select, use setTags
                setTags(filterList[0])
              }
            }
          }
        }}
        onChange={handleSearch}
        disabled={disabled}
      />
    </InputTagStyled>
  )
}

export default SelectTags
