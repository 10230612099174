import React from 'react'
import { RadioContainer } from './styles'
import { InputTypes } from '../../types'

const Radio = (props: InputTypes) => (
  <RadioContainer>
    <input {...props} />
    <div />
  </RadioContainer>
)

export default Radio
