import React, { useState, useEffect } from 'react'
import { TableStyled, TableBelow } from './styles'
import THead from './components/THead'
import Row from './components/Row'
import { TableTypes } from './types'
import Pagination from '../Pagination'
import Select from '../Select'

const pagesToDisplayDefault = [
  { value: 10, label: '10' },
  { value: 30, label: '30' },
  { value: 50, label: '50' },
  { value: 100, label: '100' }
]

const Table = ({
  uid,
  data: rawData,
  fields,
  deleteRow,
  editRow,
  viewRow,
  pagesToDisplay = pagesToDisplayDefault,
  pagination = false,
  headCustomUtilities,
  tableTitle
}: TableTypes) => {
  // Data is converted to state, if pagination its active, data needs to be sliced
  const [data, setData] = useState<any[]>([])
  // Ordered data will keep the current status so when it get sliced don't loose the order
  const [orderedData, setOrderedData] = useState<any[]>()
  const [isLoading, setIsLoading] = useState(true)
  const [selected, setSelected] = useState<string[]>([])
  const [dataPerPage, setDataPerPage] = useState(pagesToDisplay[0])
  const [trackPage, setTrackPage] = useState({ from: 0, to: dataPerPage.value })

  useEffect(() => {
    if (pagination) {
      setData(rawData.slice(0, dataPerPage.value))
    } else {
      setData([...rawData])
    }

    setIsLoading(false)
  }, [rawData])

  if (isLoading) {
    return <h1>Loading</h1>
  }

  const handleOrdering = (orderMethod: (a: any, b: any) => number) => {
    if (pagination) {
      // We clone the rawData because sorting modifies the array
      const cloneData = [...rawData]
      // Sort the cloned data
      const orderData = cloneData.sort(orderMethod)
      // Store the current order
      setOrderedData(orderData)
      // Slice the ordered data for the pagination
      const slicedData = orderData.slice(trackPage.from, trackPage.to)
      setData(slicedData)
    } else {
      const cloneData = [...data]
      const result = cloneData.sort(orderMethod)
      setData(result)
    }
  }

  // On thead checkBox on click all all the visible ids to the selected state
  const selectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target
    if (!checked) {
      setSelected([])
    } else {
      setSelected(data.map((x) => x[uid]))
    }
  }

  // On tbody checkBox click add the current id to selected state
  const selectOne = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, name } = e.target
    if (!checked) {
      const extract = selected.filter((x) => x !== name)
      setSelected(extract)
    } else {
      setSelected([...selected, name])
    }
  }

  const rows = data.map((piece: any) => (
    <Row
      key={piece[uid]}
      uid={uid}
      data={piece}
      fields={fields}
      deleteRow={deleteRow}
      editRow={editRow}
      viewRow={viewRow}
      selected={selected}
      selectOne={selectOne}
      displayCheckBoxes={Boolean(headCustomUtilities || deleteRow)}
    />
  ))

  return (
    <div>
      <TableStyled>
        {tableTitle && <caption>{tableTitle}</caption>}
        <THead
          fields={fields}
          deleteRow={deleteRow}
          editRow={editRow}
          viewRow={viewRow}
          selected={selected}
          selectAll={selectAll}
          setSelected={setSelected}
          headCustomUtilities={headCustomUtilities}
          handleOrdering={handleOrdering}
        />
        <tbody>{rows}</tbody>
      </TableStyled>
      {pagination && (
        <TableBelow>
          <Pagination
            dataPerPage={dataPerPage.value}
            dataLength={rawData.length}
            setData={(from, to) => {
              setTrackPage({ from, to })
              if (orderedData) {
                const slicedData = orderedData.slice(from, to)
                setData(slicedData)
              } else {
                const slicedData = rawData.slice(from, to)
                setData(slicedData)
              }
            }}
          />
          <Select
            id='SelectPages'
            full
            options={pagesToDisplay}
            value={dataPerPage}
            setValue={setDataPerPage}
          />
        </TableBelow>
      )}
    </div>
  )
}
export default Table
