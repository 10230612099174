import React from 'react'
import styled from 'styled-components'
import { BsFillCaretUpFill, BsFillCaretDownFill } from 'react-icons/bs'

export const UtilitiesDescription = styled.tr`
  > th {
    > div {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
`

export const OrderContainer = styled.div`
  display: flex;
  flex-direction: row;
  > button {
    display: flex;
    flex-direction: column;
    margin-left: 8px;
    > svg {
      font-size: 10px;

      &:last-child {
        margin-top: -3px;
      }
    }
  }
`
type ChevronProps = {
  toggle: boolean
}

export const ChevronUp = styled(({ toggle, ...props }) => (
  <BsFillCaretUpFill {...props} />
))<ChevronProps>`
  color: ${({ toggle }) => (toggle ? 'var(--primary)' : 'var(--text)')};
`
export const ChevronDown = styled(({ toggle, ...props }) => (
  <BsFillCaretDownFill {...props} />
))<ChevronProps>`
  color: ${({ toggle }) => (toggle ? 'var(--primary)' : 'var(--text)')};
`
