import React, { useState, useEffect } from 'react'
import {
  UtilitiesDescription,
  OrderContainer,
  ChevronUp,
  ChevronDown
} from './styles'
import Button from '../../../Button'
import Input from '../../../Input'
import { THeadTypes } from '../../types'

type OrderByTypes = {
  field: string
  asc: boolean
  customSort?: (a: any, b: any, asc: boolean) => number
}

const THead = ({
  fields,
  deleteRow,
  editRow,
  viewRow,
  selected,
  setSelected,
  selectAll,
  headCustomUtilities,
  handleOrdering
}: THeadTypes) => {
  const [orderBy, setOrderBy] = useState<OrderByTypes>()

  const utilityButtons = () => (
    <React.Fragment>
      {headCustomUtilities &&
        headCustomUtilities.map((render) => {
          return render(selected)
        })}
      {deleteRow && (
        <Button
          onClick={() => {
            deleteRow(selected)
            setSelected([])
          }}
          disabled={selected.length === 0}
          small
          danger
          iconRight='BsTrash'
        >
          Remove {selected.length > 0 && selected.length}
        </Button>
      )}
    </React.Fragment>
  )

  useEffect(() => {
    if (orderBy) {
      const defaultSort = (a: any, b: any) => {
        let bandA = a
        let bandB = b

        // Check if its a nested object
        if (orderBy.field.includes('.')) {
          const nested = orderBy.field.split('.')
          nested.map((x) => {
            if (bandA[x]) {
              bandA = bandA[x]
            } else {
              bandA = '-'
            }
            if (bandB[x]) {
              bandB = bandB[x]
            } else {
              bandB = '-'
            }
          })
        } else {
          bandA = bandA[orderBy.field]
          bandB = bandB[orderBy.field]
        }

        let comparison = 0
        if (bandA > bandB) {
          comparison = orderBy.asc ? 1 : -1
        } else if (bandA < bandB) {
          comparison = orderBy.asc ? -1 : 1
        }
        return comparison
      }

      // Order by the short provided by the data or the default one
      handleOrdering((a, b) => {
        if (orderBy.customSort) {
          return orderBy.customSort(a, b, !orderBy.asc)
        }
        return defaultSort(a, b)
      })
    }
  }, [orderBy])

  return (
    <thead>
      {(headCustomUtilities || deleteRow) && (
        <UtilitiesDescription>
          <th colSpan={fields.length + 4} scope='colgroup'>
            <div>{utilityButtons()}</div>
          </th>
        </UtilitiesDescription>
      )}

      <tr>
        {(headCustomUtilities || deleteRow) && (
          <th>
            <Input type='checkbox' onChange={selectAll} />
          </th>
        )}
        {fields.map(
          ({ header, field, headProps, customSort, enableSorting }) =>
            header !== undefined && (
              <th key={header} scope='col' {...headProps}>
                <OrderContainer>
                  {header}
                  {field && (
                    <Button
                      onClick={() => {
                        // If the current order gets repeated, change the asc to desc
                        if (orderBy && orderBy.field === field) {
                          setOrderBy({ field, asc: !orderBy.asc, customSort })
                        } else {
                          setOrderBy({ field, asc: true, customSort })
                        }
                      }}
                      simple
                    >
                      {enableSorting && (
                        <React.Fragment>
                          <ChevronUp
                            toggle={
                              orderBy && orderBy.field === field && orderBy.asc
                            }
                          />
                          <ChevronDown
                            toggle={
                              orderBy && orderBy.field === field && !orderBy.asc
                            }
                          />
                        </React.Fragment>
                      )}
                    </Button>
                  )}
                </OrderContainer>
              </th>
            )
        )}
        {deleteRow && <th>Delete</th>}
        {editRow && <th>Edit</th>}
        {viewRow && <th>View</th>}
      </tr>
    </thead>
  )
}
export default THead
