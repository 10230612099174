import styled from 'styled-components'
import { InputTypes } from '../../types'

export const PasswordContainer = styled.div<InputTypes>`
  display: flex;
  position: relative;
  width: ${({ full }) => (full ? '100%' : '250px')};

  > button {
    position: absolute;
    right: 10px;
    top: 9px;
    padding: 0;
    margin: 0;
    background: transparent;
    border: 0;
    cursor: pointer;
    outline: none;
    > svg {
      color: var(--text);
    }
  }
`
