import styled from 'styled-components'
import Button from '../../../Button'
import { BREAK_POINTS } from '../../../../services/styles/constants'
import { Td } from '../Row/styles'

export const UtilityToolsContainer = styled(Td)`
  > button {
    margin: 0 auto;
  }

  @media (max-width: ${BREAK_POINTS.small}px) {
    > button {
      margin: 0;
    }
  }
`

export const TDanger = styled(Button)`
  &:hover,
  &:focus {
    color: var(--danger);
  }
`
export const TPrimary = styled(Button)`
  &:hover,
  &:focus {
    color: var(--primary);
  }
`
