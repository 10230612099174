import styled from 'styled-components'

export const RadioContainer = styled.div`
  width: 16px;
  height: 16px;
  position: relative;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--border);
  z-index: 1;
  transition: border-color 0.2s ease-in-out;
  background-color: var(--white);
  > input {
    position: absolute;
    z-index: 2;
    opacity: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    &:focus {
      outline: none;
    }
  }

  > div {
    width: 85%;
    height: 85%;
    border-radius: 100%;
    background-color: transparent;
    transition: background-color 0.2s ease-in-out;
  }

  > input[type='radio']:checked + div {
    background-color: var(--primary);
  }

  &:focus-within {
    border-color: var(--primary);
  }
`
