import React from 'react'
import styled, { css } from 'styled-components'
import { ButtonCssTypes, ButtonTypes } from './types'

const ButtonBasics = css<ButtonCssTypes>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ full }) => (full ? '100%' : 'auto')};
  padding: 8px 16px;
  transition: 0.3s all ease-in-out;
  font-size: ${({ small }) => (small ? '1rem' : '1.333rem')};
  border-radius: 3px;
  border: 1px solid var(--primary);
  cursor: pointer;
  &:focus {
    outline: none;
  }
`

const Disabled = css<ButtonCssTypes>`
  cursor: auto;
  background-color: ${({ simple }) =>
    simple ? 'transparent' : 'var(--background-disabled)'};
  color: var(--text-disabled);
  border-color: var(--text-disabled);
`

const Classic = css<ButtonCssTypes>`
  ${ButtonBasics}
  background-color: ${({ secondary }) =>
    secondary ? 'transparent' : 'var(--primary)'};
  color: ${({ secondary }) =>
    secondary ? 'var(--primary)' : 'var(--background)'};
  &:hover,
  &:focus {
    background-color: ${({ secondary }) =>
      secondary ? 'transparent' : 'var(--primary-hover)'};
    border-color: var(--primary-hover);
    color: ${({ secondary }) =>
      secondary ? 'var(--primary-hover)' : 'var(--background)'};
  }
`

const Ghost = css<ButtonCssTypes>`
  ${ButtonBasics}
  background-color: transparent;
  color: ${({ danger }) => (danger ? 'var(--danger)' : 'var(--primary)')};
  border: 0;
  &:hover,
  &:focus {
    background-color: ${({ danger }) =>
      danger ? 'var(--danger-25)' : 'var(--primary-25)'};
    border: 0;
    color: ${({ danger }) => (danger ? 'var(--danger)' : 'var(--primary)')};
  }
`

const Danger = css<ButtonCssTypes>`
  ${ButtonBasics}
  background-color: ${({ secondary }) =>
    secondary ? 'transparent' : 'var(--danger)'};
  color: ${({ secondary }) =>
    secondary ? 'var(--danger)' : 'var(--background)'};
  border-color: var(--danger);
  &:hover,
  &:focus {
    background-color: ${({ secondary }) =>
      secondary ? 'transparent' : 'var(--danger-hover)'};
    border-color: var(--danger-hover);
    color: ${({ secondary }) =>
      secondary ? 'var(--danger-hover)' : 'var(--background)'};
  }
`

const Simple = css<ButtonCssTypes>`
  background-color: transparent;
  color: var(--text);
  padding: 0;
  transition: 0.3s all ease-in-out;
  font-size: 1rem;
  border-radius: 0;
  border: 0;
  cursor: pointer;
  font-weight: normal;
  &:hover,
  &:focus {
    background-color: transparent;
    border-color: transparent;
    color: var(--text);
  }
`

export const ButtonCSS = css<ButtonCssTypes>`
  ${Classic}
  ${({ danger }) => danger && Danger}
  ${({ ghost }) => ghost && Ghost}
  ${({ simple }) => simple && Simple}
  &:disabled {
    ${Disabled};
  }
`

export const ButtonStyled = styled<React.FC<ButtonTypes>>(
  ({
    secondary,
    simple,
    danger,
    full,
    iconLeft,
    iconRight,
    small,
    ghost,
    ...props
  }) => <button type='button' {...props} />
)`
  ${ButtonCSS}
`
