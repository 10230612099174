import styled from 'styled-components'
import { BREAK_POINTS } from '../../services/styles/constants'

export const TableStyled = styled.table`
  width: 100%;
  border-spacing: 1px;
  > caption {
    font-size: 1.666rem;
    margin: 0.5em 0 0.75em;
    text-align: left;
  }

  > thead {
    > tr {
      > th {
        font-size: 0.85rem;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        padding: 16px;
        text-align: left;
        background-color: var(--white);
      }
      &:first-child {
        > th:first-child {
          border-top-left-radius: 3px;
        }

        > th:last-child {
          border-top-right-radius: 3px;
        }
      }
    }
  }

  @media (max-width: ${BREAK_POINTS.small}px) {
    width: 100%;
    > caption {
      font-size: 1.3rem;
    }

    > thead {
      > tr:last-child {
        display: none;
      }
    }
    > tbody {
      > tr {
        display: flex;
        flex-direction: column;
        margin-bottom: 16px;
      }
    }
  }
`
export const TableBelow = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  > div:last-child {
    margin-top: 14px;
    width: 100px;
    margin-left: 16px;
  }

  @media (max-width: ${BREAK_POINTS.small}px) {
    flex-wrap: wrap-reverse;
    justify-content: center;
    > div:first-child {
      flex-wrap: wrap;
    }
    > div:last-child {
      width: 100%;
      margin-left: 0;
    }
  }
`
