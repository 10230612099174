import styled from 'styled-components'
import { motion } from 'framer-motion'
import { BREAK_POINTS } from '../../services/styles/constants'

export const ModalStyled = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: var(--modal);
  display: flex;
  justify-content: center;
  align-items: center;
`
export const ModalCard = styled(motion.div)`
  display: flex;
  flex-direction: column;
  padding: 24px;
  max-width: 50%;
  min-width: 40%;
  background-color: var(--white);
  position: relative;
  border-radius: 3px;

  > div:first-of-type {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--border);
    padding-bottom: 6px;
    margin-bottom: 24px;
    > button {
      font-size: 30px;
    }
    > h3 {
      margin: 0;
    }
  }

  @media (max-width: ${BREAK_POINTS.small}px) {
    width: 90%;
    max-width: 90%;
  }
`
export const ModalFooter = styled.div`
  padding-top: 16px;
  display: flex;
  justify-content: flex-end;
  > button:first-of-type {
    margin-right: 16px;
  }

  @media (max-width: ${BREAK_POINTS.small}px) {
    > button {
      width: 100%;
    }
  }
`
