import styled from 'styled-components'

export const CheckboxStyled = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 3px;
  position: relative;
  background-color: var(--white);
  > input {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 2;
    left: 0;
    top: 0;
  }

  > div {
    width: 100%;
    height: 100%;
    position: relative;
    border-radius: 3px;
    z-index: 1;
    border: 1px solid var(--border);
    transition: all 0.2s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
    > svg {
      position: absolute;
      color: white;
      display: none;
    }
  }

  > input[type='checkbox']:checked + div {
    background-color: var(--primary);
    display: block;
    border-color: var(--primary);
    > svg {
      display: block;
    }
  }

  > input[type='checkbox']:focus + div {
    border-color: var(--primary);
  }
`
