import styled from 'styled-components'
import { InputStyledBase } from '../Input/styles'

type InputTagStyledTypes = {
  isFocus: boolean
  isError?: boolean
  isDisabled?: boolean
  full: boolean
}

export const InputTagStyled = styled.div<InputTagStyledTypes>`
  ${InputStyledBase}
  display: flex;
  padding: 0;
  height: 36px;
  overflow: auto;
  position: relative;

  ${({ isFocus }) =>
    isFocus && {
      borderColor: 'var(--primary)'
    }}

  ${({ isError }) =>
    isError && {
      borderColor: 'var(--danger)'
    }}

  ${({ isDisabled }) =>
    isDisabled && {
      backgroundColor: 'var(--background-disabled)',
      cursor: 'not-allowed'
    }}

  > input {
    ${InputStyledBase}
    height: 100%;
    width: 100%;
    min-width: 150px;
    margin: 0;
    border: 0;
    padding-right: 30px;
    background-color: transparent;
    &:disabled {
      cursor: not-allowed;
    }
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  & {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
`
type OptionsContainerTypes = {
  isFocus: boolean
  isEmpty: boolean
}

export const OptionsContainer = styled.div<OptionsContainerTypes>`
  display: ${({ isFocus }) => (isFocus ? 'none' : 'flex')};
  display: flex;
  align-items: center;
  padding: 0 ${({ isEmpty }) => (isEmpty ? 0 : 16)}px;
  > div {
    margin-right: 8px;
    &:last-child {
      margin-right: 0;
    }
    > button {
      padding: 0;
      font-size: 13px;
      padding: 4px 6px;
      white-space: nowrap;
      > svg {
        font-size: 13px;
      }
    }
  }
`
