import styled from 'styled-components'
import { LabelTypes } from './types'

export const LabelStyled = styled.p`
  font-size: 0.833rem;
  margin-bottom: 8px;
  color: var(--text-disabled);
`

export const LabelContainer = styled.label<LabelTypes>`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ error, info }) => (!error && !info ? 0 : 8)}px;
  width: ${({ full }) => (full ? '100%' : '250px')};
`
export const InputInfo = styled.p`
  font-size: 0.666rem;
  color: var(--text-disabled);
  margin-top: 4px;
`
export const InputError = styled.p`
  font-size: 0.666rem;
  color: var(--danger);
  margin-top: 4px;
`
