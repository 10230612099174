import React, { useState } from 'react'
import { PasswordContainer } from './styles'
import { BsEye, BsEyeSlash } from 'react-icons/bs'
import { InputStyled } from '../../styles'
import { InputTypes } from '../../types'

const Password = ({ type, ...props }: InputTypes) => {
  const [toggle, setToggle] = useState(false)

  return (
    <PasswordContainer full={props.full}>
      <InputStyled type={toggle ? 'text' : 'password'} {...props} />
      {toggle ? (
        <button type='button' onClick={() => setToggle(false)}>
          <BsEye size={20} />
        </button>
      ) : (
        <button type='button' onClick={() => setToggle(true)}>
          <BsEyeSlash size={20} />
        </button>
      )}
    </PasswordContainer>
  )
}

export default Password
