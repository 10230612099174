import React from 'react'
import { InputStyled } from './styles'
import { InputTypes } from './types'
import Label from '../Label'
import Checkbox from './components/Checkbox'
import Password from './components/Password'
import Radio from './components/Radio'

const Input: React.FC<InputTypes> = ({
  full,
  error,
  info,
  label,
  ...props
}) => {
  const renderInputs = () => {
    if (props.type === 'checkbox') {
      return <Checkbox {...props} />
    }
    if (props.type === 'password') {
      return <Password full={full} error={error} {...props} />
    }
    if (props.type === 'radio') {
      return <Radio {...props} />
    }

    return <InputStyled full={full} error={error} {...props} />
  }

  if (!label && !error && !info) return renderInputs()

  return (
    <Label
      htmlFor={props.id}
      full={full}
      error={error}
      label={label}
      info={info}
    >
      {renderInputs()}
    </Label>
  )
}

export default Input
