import React from 'react'
import { CheckboxStyled } from './styles'
import { BsCheck } from 'react-icons/bs'

const Checkbox = (props: React.InputHTMLAttributes<HTMLInputElement>) => (
  <CheckboxStyled>
    <input {...props} />
    <div>
      <BsCheck size={14} />
    </div>
  </CheckboxStyled>
)

export default Checkbox
