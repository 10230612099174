import styled from 'styled-components'

type SearchContainerTypes = {
  error?: string
  disabled: boolean
  full: boolean
}

export const SearchContainer = styled.div<SearchContainerTypes>`
  display: flex;
  align-items: center;
  position: relative;

  > input {
    ${({ error }) => error && { borderColor: 'var(--danger)' }}
    width: ${({ full }) => (full ? '100%' : '250px')};
    &:disabled {
      color: var(--background-disabled);
      cursor: not-allowed;
    }
  }
  > button {
    cursor: pointer;
    position: absolute;
    right: 8px;
    height: 16px;
    transform: rotate(0deg);
    z-index: 10;
    padding: 0;
    background-color: transparent;
    border: 0;
    color: var(--text);
    transition: color 0.2s ease-in-out;
    &:hover,
    &:focus {
      color: var(--primary);
      outline: 0;
    }
    &:disabled {
      color: var(--text-disabled);
      cursor: not-allowed;
    }
  }
`

type SelectContainerTypes = {
  active: boolean
  full: boolean
}

export const SelectContainer = styled.div<SelectContainerTypes>`
  display: flex;
  flex-direction: column;
  position: relative;
  width: ${({ full }) => (full ? '100%' : '250px')};

  > div:nth-child(2) {
    position: relative;

    > div {
      width: 100%;
      position: absolute;
      z-index: 10;
      border: 1px solid var(--border);
      border-radius: 3px;
      padding: 8px;
      margin-top: 8px;

      > p {
        margin: 0;
        color: var(--text-disabled);
      }
    }
    > ul {
      width: 100%;
      position: absolute;
      opacity: 0;
      z-index: 10;
      border: 1px solid var(--border);
      border-radius: 3px;
      padding: 8px;
      max-height: 154px;
      overflow: scroll;
      background-color: var(--white);
      > li {
        &:first-child {
          > button {
            ${({ active }) =>
              active && {
                backgroundColor: 'var(--primary-25)'
              }}
          }
        }
        > button {
          text-transform: capitalize;
          display: flex;
          justify-content: space-between;
          padding: 8px;
          border-radius: 3px;
          &:hover,
          &:focus {
            background-color: var(--primary-25);
          }
        }
      }
    }
  }
`
