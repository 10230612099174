import styled, { css } from 'styled-components'
import { InputTypes } from './types'

export const InputStyledBase = css<InputTypes>`
  width: ${({ full }) => (full ? '100%' : '250px')};
  border-radius: 3px;
  background-color: var(--white);
  border: 1px solid
    ${({ error }) => (error ? 'var(--danger)' : 'var(--border)')};
  padding: 8px;
  color: var(--text);
  transition: border-color 0.2s ease-in-out;

  &:focus {
    outline: none;
    border-color: var(--primary);
  }

  &:disabled {
    background-color: var(--background-disabled);
    cursor: not-allowed;
  }
`

export const InputStyled = styled.input<InputTypes>`
  ${InputStyledBase}

  &[type='color'] {
    width: 50px;
    padding: 0;
  }
  &[type='radio'] {
    width: auto;
  }
  &[type='range'] {
    border: 0;
    padding: 0px;
    appearance: none;
    border: 1px solid var(--border);
    height: 12px;
    border-radius: 8px;
    background-color: var(--white);
    overflow: hidden;

    &:focus {
      border: 1px solid var(--primary);
    }

    &::-webkit-slider-thumb {
      appearance: none;
      width: 10px;
      height: 10px;
      background: black;
      border-radius: 100%;
      cursor: pointer;
      box-shadow: -1000px 0 0 995px var(--primary);
    }

    &::-moz-range-thumb {
      appearance: none;
      width: 10px;
      height: 10px;
      background: black;
      border-radius: 100%;
      border: 0;
      cursor: pointer;
      box-shadow: -1000px 0 0 995px var(--primary);
    }

    &::-moz-range-progress {
      background-color: var(--primary);
    }
    &::-moz-range-track {
      background-color: transparent;
    }

    &::-ms-fill-lower {
      background-color: var(--primary);
    }
    &::-ms-fill-upper {
      background-color: transparent;
    }
  }
`
