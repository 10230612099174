import React from 'react'
import { TDanger, TPrimary, UtilityToolsContainer } from './styles'
import { UtilityToolsTypes } from '../../types'

const UtilityTools = ({
  uid,
  deleteRow,
  editRow,
  viewRow
}: UtilityToolsTypes) => (
  <React.Fragment>
    {deleteRow && (
      <UtilityToolsContainer data-label='Delete'>
        <TDanger simple iconRight='BsTrash' onClick={() => deleteRow([uid])} />
      </UtilityToolsContainer>
    )}

    {editRow && (
      <UtilityToolsContainer data-label='Edit'>
        <TPrimary simple iconRight='BsPencil' onClick={() => editRow(uid)} />
      </UtilityToolsContainer>
    )}
    {viewRow && (
      <UtilityToolsContainer data-label='View'>
        <TPrimary simple iconRight='BsEye' onClick={() => viewRow(uid)} />
      </UtilityToolsContainer>
    )}
  </React.Fragment>
)

export default UtilityTools
